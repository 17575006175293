$(document).ready(function () {
  var countDownDateLive = new Date($('.musicians.index .time-left-live').data('timeleft')).getTime();
  var nowReload = new Date().getTime();

  var countDown = (idDay, idHour, idMin, idSec,countDownDate) => {
    var now = new Date().getTime();
    var timeleft = countDownDate - now;

    if (timeleft < 0) {
      clearInterval(countDown);
      $(idDay).html("0 Days");
      $(idHour).html("0 Hours, ");
      $(idMin).html("0 Minutes");
      $(idSec).html("0 Seconds")
      window.location.reload()
      return
    }

    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / (1000));

    $(idDay).html(days + " Days");
    $(idHour).html(hours + " Hours, ");
    $(idMin).html(minutes + " Minutes");
    $(idSec).html(seconds + " Seconds")
  };

  // setInterval(countDown('#vote-days','#vote-hours', '#vote-mins', countDownDateVote), 1000); 
  if (countDownDateLive - nowReload > 0) {
    setInterval(function () {
       countDown('#live-days', '#live-hours', '#live-mins', '#live-secs',countDownDateLive)
    }, 1000);
  }
})